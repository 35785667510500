<template>
	<!--五标三工区公众号录入过往车辆信息-->
	<div style="margin-top: 25%;overflow: hidden">
		<div style="width: 80%;margin-top: 25%;margin-left: 20%">
			<el-select v-model="car_num" class="m-2" filterable  placeholder="请选择车牌" size="large">
				<el-option
					v-for="item in car_nums"
					:key="item.id"
					:label="item.carNum"
					:value="item.carNum"
				/>
			</el-select>
		</div>
		<div style="width: 60%;margin-top: 25%;margin-left: 20%">
			<el-input v-model="weight" placeholder="请输入重量"/>
		</div>
		<div style="width: 60%;margin-top: 25%;margin-left: 20%">
			<el-select v-model="pic_url" class="m-2" placeholder="请输入来往地方" size="large">
				<el-option
					v-for="item in pic_urls"
					:key="item.id"
					:label="item.carNum"
					:value="item.carNum"
				/>
			</el-select>
		</div>
		<div style="width: 100%;margin-top: 25%;margin-left: 20%">
			<el-button style="width: 60%;" type="success" @click="submit_car_message">提交</el-button>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			car_nums: [],
			car_num: '',
			weight: '',
			pic_url: '',
			pic_urls: [],
			bdst: ''
		}
	},
	created() {
		this.bdst = this.$route.params.bdst
		this.getCarNum()
		this.getPlace()
	},
	methods: {
		// 查询车牌信息
		getCarNum(){
			this.axios.get('/carFt/getCarNum', (response) => {
				this.car_nums = response.obj
			},{
				flag: 0,
				bdst: this.bdst
			})
		},
		// 查询地点信息
		getPlace(){
			this.axios.get('/carFt/getCarNum', (response) => {
				this.pic_urls = response.obj
			},{
				flag: 1,
				bdst: this.bdst
			})
		},
		// 提交信息
		submit_car_message(){
			if (this.car_num === ''){
				ElMessage.error('有信息未填写!')
			}else {
				this.axios.post('/carGps/saveCarFt', (response) => {
					if (response.obj){
						ElMessage.success('提交成功!')
						this.reload()
					}else {
						ElMessage.error('提交失败，请联系科信部!')
					}
				},{
					car_num: this.car_num,
					weight: this.weight,
					pic_url: this.pic_url,
					bdst: this.bdst
				})
			}
		}
	}
}
</script>

<style>

</style>